import React from 'react';
import ModalContextProvider from '../../context/ModalContext/ModalContextProvider';
import CreateAPasswordModal from './CreateAPasswordModal';

export default function CreateAPasswordModalWrapper() {
  return (
    <ModalContextProvider>
      <CreateAPasswordModal />
    </ModalContextProvider>
  );
}
