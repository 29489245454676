import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { RememberedFormContainerDefaultProps, RememberedFormContainerPropTypes } from './props';
import { AUTHENTICATION_REMEMBERED_QUERY, LOGOUT_MUTATION } from './operations';
import RememberedSignInForm from './RememberedSignInForm';
import { ERROR_MESSAGE } from '../Messages/Messages';

export default function RememberedFormContainer({
  onForgotPasswordClick,
  onFailedSubmission,
  onLogoutCompleted,
  onSuccessfulSubmission,
}) {
  const { data, loading, error } = useQuery(
    AUTHENTICATION_REMEMBERED_QUERY,
    { context: { batch: true } },
  );

  const [logout] = useMutation(LOGOUT_MUTATION, { onCompleted: onLogoutCompleted });

  if (loading) return null;
  if (error) return ERROR_MESSAGE;

  const { userInfo } = data;
  const { email } = userInfo;

  return (
    <RememberedSignInForm
      email={email}
      onFailedSubmission={onFailedSubmission}
      onForgotPasswordClick={onForgotPasswordClick}
      onNotYouClick={logout}
      onSuccessfulSubmission={onSuccessfulSubmission}
    />
  );
}

RememberedFormContainer.propTypes = RememberedFormContainerPropTypes;
RememberedFormContainer.defaultProps = RememberedFormContainerDefaultProps;
