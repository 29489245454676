import React from 'react';
import { GenericRecommendationsContextProvider, RecommendationGridSliders } from '@xp-utilities/web';
import { handleOnRecommendedProductClick } from './helpers';
import { placementsProp } from './props';

const propTypes = { placements: placementsProp };
const defaultProps = { placements: [] };

export default function RecommendationGridSlidersContainer({ placements }) {
  return (
    <GenericRecommendationsContextProvider>
      <RecommendationGridSliders
        placements={placements}
        onProductClick={handleOnRecommendedProductClick}
        queryOptions={{ fetchPolicy: 'cache-first' }}
      />
    </GenericRecommendationsContextProvider>
  );
}

RecommendationGridSlidersContainer.propTypes = propTypes;
RecommendationGridSlidersContainer.defaultProps = defaultProps;
