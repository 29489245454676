/* eslint-disable react/jsx-props-no-spreading */
import { React, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import AccountBlock from '../Common/AccountBlock/AccountBlock';
import SectionHeading from '../Common/SectionHeading/SectionHeading';
import { ERROR_MESSAGE } from '../Messages/Messages';
import GiftCardBalanceForm from './GiftCardBalanceForm';
import OrderTrackingForm from './OrderTrackingForm';
import ViewOrderHistoryButton from './ViewOrderHistoryButton';
import { ORDERHELP_SELFSERVICE_PAGE_QUERY } from './operations';
import AdditionalInfo from './AdditionalInfo';

export default function SelfServiceOrderHelpPages() {
  const pageName = useMemo(() => {
    const pathSegments = window.location.pathname.split('/');
    return pathSegments[pathSegments.length - 1];
  }, []);

  const { data: queryData, loading: queryLoading, error } = useQuery(
    ORDERHELP_SELFSERVICE_PAGE_QUERY,
  );

  if (queryLoading) return null;
  if (error) return ERROR_MESSAGE;

  const { textFor, userState = {}, config = {} } = queryData || {};
  const {
    trackMyOrder,
    startMyReturn,
    trackMyReturn,
    lookUpMyOrder,
    checkGiftCardBalance,
    viewOrderDetailsText,
    orderNumberText,
    emailAddressText,
    returnOrderNumberText,
    returnOrderNumberTooltipText,
    orderNumberTooltipText,
    fromOrderHistoryText,
    signInToViewOrderHistoryText,
    viewOrderHistory,
    additionalInfoText,
    trackMyOrderAdditionalInfoText,
    returnsAdditionalInfoText,
    giftCardAdditionalInfo,
  } = textFor || {};

  const { isLoggedIn = false } = userState;
  const { enableMFEAuthenticationModal, userOrderHistoryDisplayUrl } = config || {};

  const helpPageTitle = (() => {
    switch (pageName) {
      case 'trackorder':
        return trackMyOrder?.value;
      case 'startmyreturn':
        return startMyReturn?.value;
      case 'trackmyreturn':
        return trackMyReturn?.value;
      case 'lookup-order':
        return lookUpMyOrder?.value;
      case 'giftcard-balance-check':
        return checkGiftCardBalance?.value;
      default:
        return '';
    }
  })();

  const getPageButtonText = (page) => {
    switch (page) {
      case 'trackorder':
        return trackMyOrder?.value;
      case 'startmyreturn':
        return startMyReturn?.value;
      case 'lookup-order':
        return viewOrderDetailsText?.value;
      default:
        return '';
    }
  };

  const renderForm = () => {
    const commonProps = {
      orderNumberText: orderNumberText?.value,
      emailAddressText: emailAddressText?.value,
      orderNumberTooltipText,
      toolTipLabel: orderNumberTooltipText?.value,
    };
    switch (pageName) {
      case 'trackorder':
      case 'startmyreturn':
      case 'lookup-order':
        return (
          <OrderTrackingForm
            orderHelpButtonText={getPageButtonText(pageName)}
            {...commonProps}
          />
        );
      case 'trackmyreturn':
        return (
          <OrderTrackingForm
            orderHelpButtonText={trackMyReturn?.value}
            orderNumberText={returnOrderNumberText?.value}
            emailAddressText={emailAddressText?.value}
            orderNumberTooltipText={returnOrderNumberTooltipText}
            toolTipLabel={returnOrderNumberTooltipText?.value}
          />
        );
      case 'giftcard-balance-check':
        return <GiftCardBalanceForm />;
      default:
        return null;
    }
  };

  const SectionContent = (() => {
    switch (pageName) {
      case 'trackorder':
        return trackMyOrderAdditionalInfoText;
      case 'startmyreturn':
        return returnsAdditionalInfoText;
      case 'trackmyreturn':
        return returnsAdditionalInfoText;
      case 'lookup-order':
        return '';
      case 'giftcard-balance-check':
        return giftCardAdditionalInfo;
      default:
        return '';
    }
  })();

  const params = new URLSearchParams(window.location.search);
  params.append('pagefm', 'navigation - self service');
  window.history.replaceState(null, '', params);

  return (
    <AccountBlock
      heading={(
        <SectionHeading>
          <h1 className="h1">
            {helpPageTitle}
          </h1>
        </SectionHeading>
      )}
      classList="self-service-account-block"
    >
      <div className="form-help-container view-order-history">
        <ViewOrderHistoryButton
          viewOrderHistory={viewOrderHistory}
          signInToViewOrderHistoryText={signInToViewOrderHistoryText}
          fromOrderHistoryText={fromOrderHistoryText}
          isLoggedIn={isLoggedIn}
          enableMFEAuthenticationModal={enableMFEAuthenticationModal}
          userOrderHistoryDisplayUrl={userOrderHistoryDisplayUrl}
        />
      </div>
      <div className="form-help-container">
        {renderForm()}
      </div>
      <hr />
      <AdditionalInfo
        SectionTitle={additionalInfoText}
        SectionContent={SectionContent}
      />
    </AccountBlock>
  );
}
