import { Button, TransactionRow, TransactionSummary } from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { labelValuePairProp, textProp } from '../../tools/customProps';
import AccountBlock from '../Common/AccountBlock/AccountBlock';
import { Column, Row } from '../Common/Grid';
import SectionHeading from '../Common/SectionHeading/SectionHeading';
import TmntHtml from '../Common/Text/TmntHtml';
import TmntText from '../Common/Text/TmntText';
import CTAButtons from './CTAButtons';
import CancelOrderModal from './CancelOrderModalContainer';
import CancelReturnExchangeModal from './CancelReturnExchangeModal';

const cancelModalIds = { order: 'cancelOrderModal', rne: 'cancelRnEModal' };

function CancelOrderControls({ email, label, orderNumber }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button
        isFullWidth
        onClick={() => setIsOpen(true)}
        variant="tertiary-light"
      >
        <TmntText tmnt={label} />
      </Button>
      <CancelOrderModal
        email={email}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        orderNumber={orderNumber}
      />
    </>
  );
}

CancelOrderControls.propTypes = {
  email: PropTypes.string.isRequired,
  label: textProp.isRequired,
  orderNumber: PropTypes.string.isRequired,
};

function CancelRnEControls({ email, orderNumber, label }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button
        isFullWidth
        onClick={() => setIsOpen(true)}
        variant="tertiary-light"
      >
        <TmntText tmnt={label} />
      </Button>
      <CancelReturnExchangeModal
        email={email}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        orderNumber={orderNumber}
      />
    </>
  );
}

CancelRnEControls.propTypes = {
  email: PropTypes.string.isRequired,
  label: textProp.isRequired,
  orderNumber: PropTypes.string.isRequired,
};

export default function OrderHistoryBlock({
  ctaGroup,
  detailsLink,
  emptyContent,
  heading,
  isEmpty,
  number,
  status,
  summary,
  enableReturnExchangeFlag,
}) {
  return (
    <AccountBlock
      heading={(
        <SectionHeading>
          <span className="h3">
            <TmntText tmnt={heading} />
          </span>
        </SectionHeading>
      )}
      rightHeading={
        !isEmpty
          ? (
            <SectionHeading alignedRight icon={status?.value}>
              <span className="h3">
                <TmntText tmnt={status?.label} />
              </span>
            </SectionHeading>
          )
          : null
      }
    >
      {
        !isEmpty ? (
          <Row>
            <Column md="4">
              {
                summary && (
                  <TransactionSummary
                    isNegative={summary.isNegative}
                    itemColumnLabel={<TmntText tmnt={summary.itemLabel} />}
                    priceColumnLabel={<TmntText tmnt={summary.priceLabel} />}
                    itemSummaryLabel={<TmntText tmnt={summary.footer?.label} />}
                    // TODO: Use decoded html entities here
                    priceSummaryLabel={<TmntHtml tmnt={{ key: '', value: summary.footer?.price }} />}
                  >
                    {
                      summary.rows && summary.rows.map(({ label, price, isNegative }) => (
                        <TransactionRow
                          itemLabel={<TmntText tmnt={label} />}
                          // TODO: Use decoded html entities here
                          priceLabel={<TmntHtml tmnt={{ key: '', value: price }} />}
                          isNegative={isNegative}
                          key={`${number}_${label?.value}`}
                        />
                      ))
                    }
                  </TransactionSummary>
                )
              }
            </Column>
            <Column md="4">
              {
                detailsLink && ctaGroup && (
                  <CTAButtons
                    detailsHref={detailsLink?.value}
                    detailsText={<TmntText tmnt={detailsLink?.label} />}
                  >
                    {
                      ctaGroup.map((cta) => {
                        const { label, value } = cta;
                        const [modalId, , orderNumber, email] = value.split(':');
                        const key = `${number}_${cta.label.key}`;
                        if (enableReturnExchangeFlag && label.value === 'return-and-exchange-policy') {
                          return (
                            <div className="return-and-exchange-policy">
                              <TmntHtml
                                tmnt={{
                                  key: label.key,
                                  value,
                                }}
                              />
                            </div>
                          );
                        }
                        // Render cancel modal button
                        if (modalId === cancelModalIds.order) {
                          return (
                            <CancelOrderControls
                              email={email}
                              key={key}
                              label={label}
                              orderNumber={orderNumber}
                            />
                          );
                        }

                        // Render cancel RnE modal button
                        if (modalId === cancelModalIds.rne) {
                          return (
                            <CancelRnEControls
                              email={email}
                              key={key}
                              label={label}
                              orderNumber={orderNumber}
                            />
                          );
                        }

                        // Render link button
                        return (
                          <Button
                            key={key}
                            onClick={() => window.open(cta.value, cta.label.key === 'GLB_WRITE_REVIEW_CTA' ? '_blank' : '')}
                            variant="tertiary-light"
                            isFullWidth
                          >
                            <TmntText tmnt={label} />
                          </Button>
                        );
                      })
                    }
                  </CTAButtons>
                )
              }
            </Column>
          </Row>
        ) : emptyContent
      }
    </AccountBlock>
  );
}

OrderHistoryBlock.defaultProps = {
  ctaGroup: undefined,
  detailsLink: undefined,
  isEmpty: false,
  number: undefined,
  status: undefined,
  summary: undefined,
  enableReturnExchangeFlag: false,
};

const TransactionRowProp = PropTypes.shape({
  label: textProp.isRequired,
  isNegative: PropTypes.bool,
  price: PropTypes.string,
});

OrderHistoryBlock.propTypes = {
  heading: textProp.isRequired,
  emptyContent: PropTypes.node.isRequired,
  ctaGroup: PropTypes.arrayOf(labelValuePairProp),
  detailsLink: labelValuePairProp,
  isEmpty: PropTypes.bool,
  number: PropTypes.string,
  status: labelValuePairProp,
  summary: PropTypes.shape({
    itemLabel: textProp.isRequired,
    priceLabel: textProp.isRequired,
    rows: PropTypes.arrayOf(TransactionRowProp).isRequired,
    isNegative: PropTypes.bool,
    footer: TransactionRowProp,
  }),
  enableReturnExchangeFlag: PropTypes.bool,
};
