import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DropDown,
  ErrorMessage,
  InputField,
  Textarea,
} from 'anf-core-react';
import { refProp, textProp } from '../../../tools/customProps';
import { Form, FormGroup } from '../../Common/Form';
import { TmntText, TmntHtml } from '../../Common/Text';
import { emailUsFormText } from './emailUsPropTypes';

export default function EmailUsForm({
  id,
  children,
  formRef,
  invalidFields,
  isInvalid,
  label,
  onSubmit,
  text,
}) {
  const [fullName, setFullName] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [orderNum, setOrderNum] = useState(undefined);
  const [helpReason, setHelpReason] = useState(undefined);
  const [comments, setComments] = useState(undefined);

  // temp fix until design system updates, remove when
  useEffect(() => {
    const commentInput = document.getElementById('customer-email-us-form__comments');
    commentInput.setAttribute('name', 'comment');
  }, []);

  const {
    formFieldComments,
    formFieldDropDown,
    formFieldEmail,
    formFieldName,
    formFieldOrderNum,
    formReasons,
    formError,
  } = text;

  const isInvalidField = (name) => invalidFields.includes(name);

  const processOptions = (option) => ({ label: option.text?.value, value: option.optionValue });

  const renderText = () => ([<TmntHtml tmnt={formReasons} />]);

  const renderFields = () => {
    const nameIsInvalid = isInvalidField('name');
    const emailIsInvalid = isInvalidField('email');
    const contactReasonIsInvalid = isInvalidField('contactReason');
    const commentIsInvalid = isInvalidField('comment');

    return ([
      <InputField
        autoComplete="name"
        id="customer-email-us-form__name"
        key="input"
        isInvalid={nameIsInvalid}
        isRequired
        label={formFieldName?.label?.value}
        name="name"
        onChange={(e) => setFullName(e.target.value)}
        type="text"
        value={fullName}
      >
        {nameIsInvalid && formFieldName?.error?.value && (
          <ErrorMessage variant="error">
            <span data-property={formFieldName?.error?.key}>
              {formFieldName?.error?.value}
            </span>
          </ErrorMessage>
        )}
      </InputField>,
      <InputField
        autoComplete="email"
        id="customer-email-us-form__email"
        key="input"
        isInvalid={emailIsInvalid}
        isRequired
        label={formFieldEmail?.label?.value}
        name="email"
        onChange={(e) => setEmail(e.target.value)}
        type="email"
        value={email}
      >
        {emailIsInvalid && formFieldEmail?.error?.value && (
          <ErrorMessage variant="error">
            <span data-property={formFieldEmail?.error?.key}>
              {formFieldEmail?.error?.value}
            </span>
          </ErrorMessage>
        )}
      </InputField>,
      <InputField
        autoComplete="off"
        id="customer-email-us-form__order-number"
        key="input"
        isRequired={false}
        label={formFieldOrderNum?.value}
        name="orderNum"
        onChange={(e) => setOrderNum(e.target.value)}
        type="text"
        value={orderNum}
      />,
      <DropDown
        id="customer-email-us-form__how-can-we-help"
        isInvalid={contactReasonIsInvalid}
        isRequired
        label={formFieldDropDown?.label?.value}
        name="contactReason"
        onChange={(e) => setHelpReason(e.target.value)}
        options={formFieldDropDown?.options?.map(processOptions)}
        value={helpReason}
      >
        {contactReasonIsInvalid && formFieldDropDown?.error?.value && (
          <ErrorMessage variant="error">
            <span data-property={formFieldDropDown?.error?.key}>
              {formFieldDropDown?.error?.value}
            </span>
          </ErrorMessage>
        )}
      </DropDown>,
      <Textarea
        autoComplete="off"
        id="customer-email-us-form__comments"
        isInvalid={commentIsInvalid}
        isRequired
        label={formFieldComments?.label?.value}
        name="comment"
        onChange={(e) => setComments(e.target.value)}
        value={comments}
      >
        {commentIsInvalid && formFieldComments?.error?.value && (
          <ErrorMessage variant="error">
            <span data-property={formFieldComments?.error?.key}>
              {formFieldComments?.error?.value}
            </span>
          </ErrorMessage>
        )}
      </Textarea>,
    ]);
  };

  const renderFormLabel = () => (
    <span className="screen-reader-text">
      <TmntText tmnt={label} />
    </span>
  );

  const renderFormGroup = () => ([
    <FormGroup
      key="account-form-group"
      fields={[...renderText(), ...renderFields()]}
    />,
  ]);

  const renderFormError = () => formError && (
    <ErrorMessage id={`${id}-error-message`}>
      <TmntText tmnt={formError} />
    </ErrorMessage>
  );

  return (
    <Form
      id={id}
      label={renderFormLabel()}
      error={renderFormError()}
      formRef={formRef}
      groups={renderFormGroup()}
      isInvalid={isInvalid}
      onSubmit={onSubmit}
      isValidated={false}
    >
      { children }
    </Form>
  );
}

const propTypes = {
  id: PropTypes.string.isRequired,
  label: textProp.isRequired,
  text: emailUsFormText.isRequired,
  children: PropTypes.node,
  formRef: refProp,
  invalidFields: PropTypes.arrayOf(PropTypes.string),
  isInvalid: PropTypes.bool,
  onSubmit: PropTypes.func,
};

const defaultProps = {
  children: undefined,
  formRef: undefined,
  invalidFields: undefined,
  isInvalid: false,
  onSubmit: undefined,
};

EmailUsForm.propTypes = propTypes;
EmailUsForm.defaultProps = defaultProps;
