import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { gql, useQuery } from '@apollo/client';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../../Messages/Messages';

const reCaptchaKeyConfig = gql`
  {
    config {
      reCaptchaSiteKey: string(name: "reCaptchaSiteKey")
    }
  }
`;

export default function ReCaptcha({ onChange }) {
  const { loading, error: queryError, data: queryData } = useQuery(reCaptchaKeyConfig);

  if (loading) return LOADING_MESSAGE;
  if (queryError) return ERROR_MESSAGE;

  const {
    reCaptchaSiteKey,
  } = queryData.config;

  return (
    <ReCAPTCHA
      sitekey={reCaptchaSiteKey}
      onChange={onChange}
      onExpired={() => onChange('')}
      data-testid="recaptcha"
    />
  );
}

ReCaptcha.propTypes = {
  onChange: PropTypes.func.isRequired,
};
