import React from 'react';
import PropTypes from 'prop-types';
import hydrate from '../lib/hydrate';
import RecommendationsAEMSlidersContainer from '../lib/components/Recommendations/RecommendationAEMSlidersContainer';
import { placementsVar } from '../lib/context/ApplicationContext/ApplicationContext';

const frontend = 'RecommendationAEMSlidersFrontend';
const Id = 'sales-1';

const propTypes = { clientId: PropTypes.string.isRequired };

function RecommendationAEMSlidersFrontend({ clientId }) {
  return (
    <RecommendationsAEMSlidersContainer
      clientId={clientId}
      placements={placementsVar().get(clientId)}
    />
  );
}

RecommendationAEMSlidersFrontend.propTypes = propTypes;
hydrate({
  frontend,
  component: RecommendationAEMSlidersFrontend,
  clientId: Id,
});
