import { ProgressBar, DataCard, Text } from 'anf-core-react';
import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  description: PropTypes.node,
  progressBarCurrentValue: PropTypes.number.isRequired,
  progressBarMaximumValue: PropTypes.number.isRequired,
  minValueLabelProgressBar: PropTypes.string,
  maxValueLabelProgressBar: PropTypes.string,
  progressBarClassName: PropTypes.string,
};

const defaultProps = {
  children: undefined,
  description: undefined,
  minValueLabelProgressBar: '',
  maxValueLabelProgressBar: '',
  progressBarClassName: '',
};

export default function LoyaltyCard({
  title,
  description,
  children,
  progressBarCurrentValue,
  progressBarMaximumValue,
  minValueLabelProgressBar,
  maxValueLabelProgressBar,
  progressBarClassName,
}) {
  return (
    <DataCard
      headerTitle={<Text isStrong size="l" typeStyle="h2">{title}</Text>}
      theme="primary-light"
      variant="boxed"
    >
      <div className="loyalty-card">
        <div className="loyalty-sub-title">{description}</div>
        <div className="loyalty-body">
          <ProgressBar
            currentValue={progressBarCurrentValue}
            maximumValue={progressBarMaximumValue}
            classList={progressBarClassName}
          />
          <div className="loyalty-progress-bar-label">
            <span>{minValueLabelProgressBar}</span>
            <span>{maxValueLabelProgressBar}</span>
          </div>
        </div>
        <div className="loyalty-footer">{children}</div>
      </div>
    </DataCard>
  );
}

LoyaltyCard.defaultProps = defaultProps;

LoyaltyCard.propTypes = propTypes;
