import React, { useContext } from 'react';
import { Link as DSLink } from 'anf-core-react';
import { useQuery } from '@apollo/client';
import { createPasswordLinkQuery } from './operations';
import TmntText from '../Common/Text/TmntText';
import { LOADING_MESSAGE, ERROR_MESSAGE } from '../Messages/Messages';
import { ModalContext } from '../../context/ModalContext/ModalContextProvider';
import { useTealiumTrigger, useEventListener } from '../../hooks';

export default function CreateAPasswordLink() {
  const { loading, error: queryError, data: queryData } = useQuery(createPasswordLinkQuery);
  const { openModal, closeModal } = useContext(ModalContext);

  const triggerTealiumTrackAction = useTealiumTrigger('universal', 'CREATE_PASSWORD');

  const openModalAndTriggerTealium = () => {
    openModal();
    triggerTealiumTrackAction();
  };

  useEventListener('create-a-password-modal:close', closeModal);

  if (loading) return LOADING_MESSAGE;
  if (queryError) return ERROR_MESSAGE;

  const { textFor = {} } = queryData;
  const {
    createAPassword,
    createAPasswordLinkSubText,
  } = textFor;

  return (
    <div id="create-password-link-container" style={{ textAlign: 'center' }}>
      <p id="create-password-link-subtext" style={{ fontWeight: 'bold' }}>
        <TmntText tmnt={createAPasswordLinkSubText} />
      </p>
      <DSLink
        classList="create-a-password-link-btn"
        onClick={() => {
          openModalAndTriggerTealium();
          document.dispatchEvent(new CustomEvent('authentication-modal:close'));
        }}
      >
        <TmntText tmnt={createAPassword} />
      </DSLink>
    </div>
  );
}
