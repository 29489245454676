import React from 'react';
import {
  CategoryRecommendationsContextProvider,
  GenericRecommendationsContextProvider,
  PickedForYouRecommendationsContextProvider,
  SearchRecommendationsContextProvider,
  QuickviewRecommendationsContextProvider,
  MyListRecommendationsContextProvider,
  AddToBagRecommendationsContextProvider,
  NullSearchRecommendationsContextProvider,
  useDatabridgeSubscribe,
} from '@xp-utilities/web';
import {
  RecommendationsWrapperPropTypes,
  RecommendationsWrapperDefaultProps,
} from './props';
import { PAGE_KINDS } from '../../tools/constants';

export const INSTANCE_DATA_ATTRIBUTE = 'data-recommendations-client';

export default function RecommendationsWrapper({ children, clientId }) {
  const pageKind = useDatabridgeSubscribe('page.pageKind');

  const renderContainer = () => (
    <div
      id={clientId}
      data-testid={clientId}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...{ [INSTANCE_DATA_ATTRIBUTE]: clientId }}
    >
      { children }
    </div>
  );

  if (clientId === 'quickview-1') {
    return (
      <QuickviewRecommendationsContextProvider id={clientId}>
        { renderContainer() }
      </QuickviewRecommendationsContextProvider>
    );
  }

  if (clientId === 'addToBag-1') {
    return (
      <AddToBagRecommendationsContextProvider id={clientId}>
        { renderContainer() }
      </AddToBagRecommendationsContextProvider>
    );
  }

  if (clientId === 'null-search-1') {
    return (
      <NullSearchRecommendationsContextProvider>
        { renderContainer() }
      </NullSearchRecommendationsContextProvider>
    );
  }

  if (clientId === 'search-1') {
    return (
      <SearchRecommendationsContextProvider>
        { renderContainer() }
      </SearchRecommendationsContextProvider>
    );
  }
  switch (pageKind) {
    // Note: add more pageKinds as needed per context
    case PAGE_KINDS.pickedForYou:
      return (
        <PickedForYouRecommendationsContextProvider>
          { renderContainer() }
        </PickedForYouRecommendationsContextProvider>
      );
    case PAGE_KINDS.category:
      return (
        <CategoryRecommendationsContextProvider id={clientId}>
          { renderContainer() }
        </CategoryRecommendationsContextProvider>
      );
    case PAGE_KINDS.myList:
      return (
        <MyListRecommendationsContextProvider>
          { renderContainer() }
        </MyListRecommendationsContextProvider>
      );
    default:
      return (
        <GenericRecommendationsContextProvider>
          { renderContainer() }
        </GenericRecommendationsContextProvider>
      );
  }
}

RecommendationsWrapper.propTypes = RecommendationsWrapperPropTypes;
RecommendationsWrapper.defaultProps = RecommendationsWrapperDefaultProps;
