import React from 'react';
import PropTypes from 'prop-types';
import { refProp } from '../../tools/customProps';

const propTypes = {
  children: PropTypes.node.isRequired,
  closeButtonLabel: PropTypes.node,
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  promptRef: refProp.isRequired,
};

const defaultProps = {
  closeButtonLabel: '',
  id: '',
};

export default function UpperFunnelPrompt({
  children,
  closeButtonLabel,
  id,
  onClose,
  promptRef,
}) {
  return (
    <div
      data-testid="uf-prompt"
      id={id}
      ref={promptRef}
    >
      <button
        className="icon-button"
        onClick={onClose}
        type="button"
        data-theme="inverse"
        aria-label={closeButtonLabel}
      >
        <span data-icon="close" />
      </button>
      {children}
    </div>
  );
}

UpperFunnelPrompt.propTypes = propTypes;
UpperFunnelPrompt.defaultProps = defaultProps;
