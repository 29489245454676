import React, { useContext } from 'react';
import { createPortal } from 'react-dom';
import { useQuery } from '@apollo/client';
import SignInButton from './SignInButton';
import LoggedInButton from './LoggedInButton';
import SignInMenu from './SignInMenu';
import SignInToaster from './SignInToaster';
import { LoadingButton } from '../Messages/Messages';
import { SIGN_IN_BLOCK_USER_STATE_QUERY } from './operations';
import { SignInToasterContext } from '../../context/ModalContext';
import AuthenticationModal from './AuthenticationModal';
import UpperFunnelLogin from '../UpperFunnelLogin/UpperFunnelLogin';
import getFrontendId from '../../../tools/getFrontendId';
import { MOBILE_SIGN_IN_MENU_FRONTEND } from '../../tools/constants';

const SIGN_IN_BLOCK = 'signin-block';

const renderContainer = (children) => (
  <div className={SIGN_IN_BLOCK} data-testid={SIGN_IN_BLOCK}>
    { children }
  </div>
);

export default function SignInBlock() {
  const { openModal: openToaster } = useContext(SignInToasterContext);

  const { data = {}, loading } = useQuery(
    SIGN_IN_BLOCK_USER_STATE_QUERY,
    {
      ssr: false,
      context: { batch: true },
      fetchPolicy: 'cache-and-network',
    },
  );

  if (loading) return renderContainer(<LoadingButton />);

  const { userState = {}, config = {} } = data;
  const { isLoggedIn = false } = userState;
  const { isLoyaltyEnabled } = config;

  const mobileSignInMenuFrontendId = getFrontendId(MOBILE_SIGN_IN_MENU_FRONTEND);
  const mobileSignInContainerElement = document?.getElementById(mobileSignInMenuFrontendId);

  const renderSignInMenuComponent = (location) => (
    <SignInMenu isLoggedIn={isLoggedIn} location={location} />
  );

  return renderContainer((
    <>
      {
        isLoggedIn
          ? <LoggedInButton onClick={openToaster} />
          : <SignInButton onClick={openToaster} />
      }
      <>
        {/* Auth Modal */}
        <AuthenticationModal isLoyaltyEnabled={isLoyaltyEnabled} />
        {/* Sign In Toaster */}
        <SignInToaster>
          { renderSignInMenuComponent() }
        </SignInToaster>
        {/* Mobile Sign In */}
        {
          mobileSignInContainerElement && createPortal(
            renderSignInMenuComponent('mobile-toaster'),
            mobileSignInContainerElement,
          )
        }
        {/* Upper Funnel */}
        { !isLoggedIn ? <UpperFunnelLogin /> : null }
      </>
    </>
  ));
}
