import React, { useContext } from 'react';
import { Modal } from 'anf-core-react';
import CreateAPasswordLink from './CreateAPasswordLink';
import CreateAPasswordContainer from './CreateAPasswordContainer';
import { ModalContext } from '../../context/ModalContext/ModalContextProvider';

export default function CreateAPasswordModal() {
  const { isModalOpen, closeModal } = useContext(ModalContext);
  const key = 'loginSuccessMFE';

  const handleClose = () => {
    if (typeof window !== 'undefined') {
      const isLoggedIn = JSON.parse(localStorage.getItem(key)) === 'true';
      localStorage.removeItem(key);

      if (isLoggedIn) {
        window.location.reload();
      }
    }
    closeModal();
  };

  return (
    <div id="create-password-link-wrapper">
      <CreateAPasswordLink />
      <Modal
        id="create-a-password-modal"
        isOpen={isModalOpen}
        onClose={() => handleClose()}
        variant="headerless"
      >
        <div className="scope-1892 slick-styles">
          <CreateAPasswordContainer />
        </div>
      </Modal>
    </div>
  );
}
