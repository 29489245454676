import React, { useState } from 'react';
import {
  Button,
  FieldGroup,
  HorizontalSeparator,
  Icon,
  Link as DSLink,
} from 'anf-core-react';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { Form } from '../Common/Form';
import { TmntText } from '../Common/Text';
import * as FormStructure from '../FormStructure';
import { SIGN_IN_FORM_QUERY } from './operations';
import { ERROR_MESSAGE } from '../Messages/Messages';
import KeepMeSignedIn from '../Common/KeepMeSignedIn/KeepMeSignedIn';
import { KEEP_ME_SIGN_IN_INPUT_NAME } from '../Common/KeepMeSignedIn/KeepMeSignedInCheckbox';
import { LOYALTY_CONVERSION_INPUT_NAME } from './LoyaltyConversion';
import CreateAPasswordModal from '../CreateAPassword/CreateAPasswordModal';
import StepOne from './StepOne';
import useButtonState from '../Common/ButtonState/useButtonState';
import ESpot from '../ESpot/ESpot';
import ModalContextProvider from '../../context/ModalContext/ModalContextProvider';
import { useSignInFormLogin } from '../../hooks';
import { FormErrorCell, LoyaltyConversionToggle, LoyaltyAboutLinkFormCell } from './SignInFormHelpers';
import { SignInFormPropTypes, SignInFormDefaultProps } from './props';
import { useCustomTealiumTrigger } from '../../hooks/useTealiumTrigger';
import sha256 from '../../tools/encryption/sha256';

let textCache = null;

export default function SignInForm({
  email,
  keepMeSignedIn,
  onFailedSubmission,
  onForgotPasswordClick,
  onJoinClick,
  onSuccessfulSubmission,
  password,
}) {
  const [submitCall, setSubmitCall] = useState();
  const [renderButton, registerPromise] = useButtonState();
  const defaultValues = {
    email,
    password,
    [KEEP_ME_SIGN_IN_INPUT_NAME]: keepMeSignedIn,
  };

  const {
    clearErrors,
    control,
    formState,
    getValues,
    handleSubmit,
    setError,
  } = useForm({ defaultValues });

  const [runLogin, isLoyaltyConversionShown] = useSignInFormLogin({
    getPrimaryFormError: () => textCache?.errorSomethingWentWrong,
    getValues,
    onFailedSubmission,
    onSuccessfulSubmission,
    setError,
  });

  const { data = {}, loading: queryLoading, error: queryError } = useQuery(SIGN_IN_FORM_QUERY);

  /* Analytics setup */
  const { userType, userCountry, signifydId } = data;

  const analyticsData = {
    eventName: 'analytics.login_success',
    eventData: {
      event_type: 'login_success',
      user_state: userType || '',
      site_country: userCountry || '',
      user_device_id: signifydId || '',
    },
  };

  const trackSuccessfulSignIn = useCustomTealiumTrigger(analyticsData);

  /* Handlers */
  const handleOnInvalidForm = () => setError('form', { message: textCache?.signInForm?.error });

  const handleOnValidForm = async (formData = {}) => {
    const variables = {
      email: formData.email,
      password: formData.password,
      keepMeSignedIn: formData[KEEP_ME_SIGN_IN_INPUT_NAME],
      isLoyaltyConversionTermsAccepted: formData[LOYALTY_CONVERSION_INPUT_NAME],
    };

    analyticsData.eventData = {
      ...analyticsData.eventData,
      user_hashed_email: await sha256(variables.email),
      keep_me_logged_in_flag: variables.keepMeSignedIn.toString(),
    };

    try {
      const call = new Promise((resolve, reject) => {
        runLogin({ variables })
          .then(({ data: responseData }) => {
            const { login } = responseData;
            const { success } = login;
            if (!success) reject();
            else {
              trackSuccessfulSignIn();
              resolve();
            }
          })
          .catch(() => reject());
      });

      setSubmitCall(call);
      registerPromise(call);
    } catch { /* do nothing */ }
  };

  const handleOnSubmit = (event) => {
    clearErrors();
    return handleSubmit(handleOnValidForm, handleOnInvalidForm)(event);
  };

  if (queryError) return ERROR_MESSAGE;

  const { textFor = {} } = data;
  const { config = {} } = data;
  const { signInForm = {} } = textFor;

  textCache = textFor;

  /* Render Methods */
  const renderFormLabel = (label, isLoading) => (
    <FormStructure.FormGroup>
      <FormStructure.FormCell isLoading={isLoading} isHeader>
        <h1 className="h1">
          <TmntText tmnt={label} />
        </h1>
      </FormStructure.FormCell>
    </FormStructure.FormGroup>
  );

  const renderForgotPassword = () => (
    <DSLink classList="forgot-password-button" onClick={onForgotPasswordClick} align="center">
      <TmntText tmnt={textFor?.forgotPassword} />
    </DSLink>
  );

  const renderPrimaryGroup = (groups = []) => {
    if (groups?.length < 1) return null;

    /* Only one primary group - email/password */
    const group = groups[0];
    const { label, fieldList } = group;

    return (
      <FieldGroup legend={label?.value}>
        <StepOne
          fields={fieldList}
          control={control}
          showLabel={textCache?.showPasswordText?.value}
          hideLabel={textCache?.hidePasswordText?.value}
        />
      </FieldGroup>
    );
  };

  const renderEspot = (espotId, isLoyaltyEnabled, isLoading) => {
    if (!isLoyaltyEnabled) return null;

    return (
      <FormStructure.FormCell isLoading={isLoading}>
        <ESpot espotId={espotId} />
      </FormStructure.FormCell>
    );
  };

  const {
    id = 'sign-in-form',
    label,
    formGroupList,
  } = signInForm;

  return (
    <Form
      id={id}
      label={renderFormLabel(label, queryLoading)}
      isValidated={false}
      onSubmit={handleOnSubmit}
    >
      <FormStructure.FormGroup>
        { renderEspot('customer-signin-header', config?.isLoyaltyEnabled, queryLoading) }
        <FormStructure.FormCell isLoading={queryLoading}>
          { renderPrimaryGroup(formGroupList) }
        </FormStructure.FormCell>
        <FormStructure.FormCell isLoading={queryLoading}>
          <KeepMeSignedIn id="sign-in-form-keep-me-signed-in" control={control} />
        </FormStructure.FormCell>
        <LoyaltyConversionToggle
          control={control}
          isLoading={queryLoading}
          isLoyaltyConversionShown={isLoyaltyConversionShown}
          submitCall={submitCall}
        >
          <FormErrorCell formState={formState} />
          <FormStructure.FormCell isLoading={queryLoading}>
            {
              renderButton({
                initial: {
                  children: (<TmntText tmnt={textFor?.signIn} />),
                  isFullWidth: true,
                  type: 'submit',
                  variant: 'secondary',
                },
                processing: {
                  children: (<TmntText tmnt={textFor?.processing} />),
                  isFullWidth: true,
                  variant: 'secondary',
                },
                error: {
                  children: (<TmntText tmnt={textFor?.pleaseTryAgain} />),
                  isFullWidth: true,
                  variant: 'secondary',
                },
                success: {
                  children: (
                    <>
                      <Icon icon="check" />
                      <TmntText tmnt={textFor?.success} />
                    </>
                  ),
                  isFullWidth: true,
                  variant: 'secondary',
                },
              })
            }
          </FormStructure.FormCell>
          <FormStructure.FormCell isLoading={queryLoading} align="center">
            { renderForgotPassword() }
          </FormStructure.FormCell>
        </LoyaltyConversionToggle>
      </FormStructure.FormGroup>
      <FormStructure.FormGroup>
        <FormStructure.FormCell isLoading={queryLoading}>
          <hr />
        </FormStructure.FormCell>
      </FormStructure.FormGroup>
      <FormStructure.FormGroup>
        {/* Footer ESpot */}
        { renderEspot('customer-signin-footer', config?.isLoyaltyEnabled, queryLoading) }
        {/* Create Account Button */}
        <FormStructure.FormCell isLoading={queryLoading}>
          <Button
            variant="tertiary-dark"
            onClick={onJoinClick}
            type="button"
            isFullWidth
          >
            <TmntText tmnt={textFor?.createAccount} />
          </Button>
        </FormStructure.FormCell>
        {/* Loyalty About Link */}
        <LoyaltyAboutLinkFormCell
          isLoading={queryLoading}
          isLoyaltyEnabled={config?.isLoyaltyEnabled}
          link={config?.loyaltyAboutLink}
          label={textFor?.loyaltyAboutLabel}
        />
      </FormStructure.FormGroup>
      <FormStructure.FormGroup>
        <FormStructure.FormCell isLoading={queryLoading}>
          <HorizontalSeparator>
            <TmntText tmnt={textFor?.or} />
          </HorizontalSeparator>
        </FormStructure.FormCell>
      </FormStructure.FormGroup>
      {/* Sign Up In Store */}
      <FormStructure.FormGroup>
        <FormStructure.FormCell isLoading={queryLoading}>
          <ModalContextProvider>
            <CreateAPasswordModal />
          </ModalContextProvider>
        </FormStructure.FormCell>
      </FormStructure.FormGroup>
    </Form>
  );
}

SignInForm.propTypes = SignInFormPropTypes;
SignInForm.defaultProps = SignInFormDefaultProps;
