import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { RecommendationSliders } from '@xp-utilities/web';
import RecommendationsWrapper from './RecommendationsWrapper';
import {
  RecommendationAEMSlidersContainerPropTypes,
  RecommendationAEMSlidersContainerDefaultProps,
} from './props';

export default function RecommendationAEMSlidersContainer({ clientId, placements }) {
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [targetElement, setTargetElement] = useState(null);

  useEffect(() => {
    const handleContentLoaded = () => {
      setIsContentLoaded(true);
    };

    document.addEventListener('aem-content-homepage:recs', handleContentLoaded);

    return () => {
      document.removeEventListener('aem-content-homepage:recs', handleContentLoaded);
    };
  }, []);

  useEffect(() => {
    if (isContentLoaded) {
      // Find the target element when content is loaded
      const elements = document.querySelectorAll('.perso-rec-slider');
      const el = elements[0];
      if (el) {
        setTargetElement(el);
      }
    }
  }, [isContentLoaded]);

  if (!isContentLoaded || !targetElement) {
    return null;
  }

  const handleOnProductClick = (event, product = {}) => {
    const {
      brand,
      collection,
      faceout,
      id,
      imageId,
      name,
    } = product;

    const eventDetails = {
      productId: id,
      collectionId: collection,
      imageId,
      productName: name,
      productBrand: brand,
      returnFocus: event.target,
      faceout,
    };

    const quickviewEvent = new CustomEvent('quickview:openModal', { detail: eventDetails });

    window.dispatchEvent(quickviewEvent);
  };

  return ReactDOM.createPortal(
    <RecommendationsWrapper clientId={clientId}>
      <RecommendationSliders
        placements={placements}
        onProductClick={handleOnProductClick}
      />
    </RecommendationsWrapper>,
    targetElement,
  );
}

RecommendationAEMSlidersContainer.propTypes = RecommendationAEMSlidersContainerPropTypes;
RecommendationAEMSlidersContainer.defaultProps = RecommendationAEMSlidersContainerDefaultProps;
