export default function isValidPhoneNumber(phoneNumber) {
  if (!window?.digitalData) return true;

  const formattedPhoneNumber = phoneNumber.replace(/-/g, '');
  const isUSSite = window.digitalData.get('page.country') === 'US';

  const validateUSPhoneNumber = (number) => /^(\d){10}$/.test(number);
  const validateInternationalPhoneNumber = (number) => /^(\d){3,19}$/.test(number);

  return isUSSite
    ? validateUSPhoneNumber(formattedPhoneNumber)
    : validateInternationalPhoneNumber(formattedPhoneNumber);
}
