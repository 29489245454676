import { gql } from '@apollo/client';
import { textPairFragment, apiResponseFragment } from '../../query/fragments';

export const changePasswordFormQuery = gql`
query ChangePasswordFormQuery {
  textFor {
    changePasswordHeadingText: pair(pairKey: "changePasswordHeading") {...textPair}
    createNewPasswordText: pair(pairKey: "createNewPassword") {...textPair}
    saveButtonText: pair(pairKey: "save") {...textPair}
    passwordReqNoteText: pair(pairKey: "passwordReqNote") {...textPair}
    keepMeSignedInCheckboxTooltipText: pair(pairKey: "keepMeSignedInCheckboxTooltip") {...textPair}
    keepMeSignedInCheckboxText: pair(pairKey: "keepMeSignedIn") {...textPair}
    currentPasswordText: pair(pairKey: "currentPassword") {...textPair}
    newPasswordText: pair(pairKey: "newPassword") {...textPair}
    confirmPasswordText: pair(pairKey: "confirmPassword") {...textPair}
    showPasswordText: pair(pairKey: "showPassword") {...textPair}
    hidePasswordText: pair(pairKey: "hidePassword") {...textPair}
    processing: pair(pairKey: "processing") { ...textPair }
    success: pair(pairKey: "success") { ...textPair }
    pleaseTryAgain: pair(pairKey: "pleaseTryAgain") { ...textPair }
    newPasswordMatchError: pair(pairKey: "newPasswordMatchError") {...textPair}
    invalidInputError: pair(pairKey: "invalidInputError") {...textPair}
    keepMeSignedInTooltipLabel: pair(pairKey: "keepMeSignedInTooltipLabel") {...textPair}
    invalidCurrentPasswordError: pair(pairKey: "invalidCurrentPasswordError") {...textPair}
  }
}

${textPairFragment}
`;

export const changePasswordRequest = gql`
  mutation ChangePassword($currentPassword: String!, $newPassword: String!, $keepMeSignedIn: Boolean) {
    changePassword(currentPassword: $currentPassword, newPassword: $newPassword, keepMeSignedIn: $keepMeSignedIn) {
      ...response
    }
  }
  
  ${apiResponseFragment}
`;
