import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Icon, DataCard, PaymentIcon, Link as DSLink,
} from 'anf-core-react';
import TmntText from '../Common/Text/TmntText';
import { PaymentsContext } from './PaymentsContextProvider';
import { getCardBrandIcon, maskCardNumber } from '../../tools/CreditCardHelper/CreditCardHelper';
import ButtonState from '../Common/ButtonState/ButtonState';
import { cardNumberObscureChar } from './paymentConstants';

const defaultProps = {
  onSetPaymentAsDefault: undefined,
  onSetToEditState: undefined,
};

const propTypes = {
  payment: PropTypes.shape({
    cardExpireMonth: PropTypes.string.isRequired,
    cardBrand: PropTypes.string.isRequired,
    cardExpireYear: PropTypes.string.isRequired,
    cardNumber: PropTypes.string.isRequired,
    displayInfo: PropTypes.string,
    id: PropTypes.string.isRequired,
    requiresCVV: PropTypes.bool,
  }).isRequired,
  isDefault: PropTypes.bool.isRequired,
  onSetPaymentAsDefault: PropTypes.func,
  onSetToEditState: PropTypes.func,
};

export default function PaymentCard({
  isDefault,
  onSetPaymentAsDefault,
  onSetToEditState,
  payment,
}) {
  const paymentIcon = getCardBrandIcon(payment.cardBrand);
  const paymentsContext = useContext(PaymentsContext);
  const text = paymentsContext?.text;

  const handleMakeDefault = async () => onSetPaymentAsDefault(payment.id);
  const handleOnEdit = () => onSetToEditState(payment.id);

  const defaultText = (
    <>
      <hr className="payment-card-hr" />
      <div className="payment-card-default-section">
        <Icon icon="check" />
        <p className="h3 payment-card-default-text" data-testid="default-text">
          <TmntText tmnt={text.cardDefaultText} />
        </p>
      </div>
    </>
  );

  const defaultButton = (
    <ButtonState
      asyncOnClick={handleMakeDefault}
      initial={(
        <Button type="submit" variant="tertiary-dark" isFullWidth>
          <TmntText tmnt={text.buttonMakeDefault} />
        </Button>
      )}
      processing={(
        <Button variant="tertiary-dark" isProcessing isDisabled isFullWidth>
          <TmntText tmnt={text.processing} />
        </Button>
      )}
      error={(
        <Button variant="tertiary-dark" isDisabled isFullWidth>
          <TmntText tmnt={text.pleaseTryAgain} />
        </Button>
      )}
      success={(
        <Button variant="tertiary-dark" isDisabled isFullWidth>
          <Icon icon="check" />
          <TmntText tmnt={text.success} />
        </Button>
      )}
    />
  );

  return (
    <div className="payment-card-wrapper">
      <DataCard
        variant="boxed"
        headerTitle={text.cardTitle.value}
        headerContent={(
          <DSLink
            onClick={handleOnEdit}
            aria-label={text.cardAriaEndingIn}
          >
            <TmntText tmnt={text.buttonEdit} />
          </DSLink>
        )}
      >
        <>
          <div className="payment-card-top-content">
            <div className="payment-card-icon">
              <PaymentIcon
                iconName={paymentIcon}
                labelText={paymentIcon}
              />
            </div>
            <div className="payment-card-info">
              <p>{maskCardNumber(payment.cardBrand, payment.cardNumber, cardNumberObscureChar)}</p>
              <p>{`${payment.cardExpireMonth}/${payment.cardExpireYear}`}</p>
            </div>
          </div>
          <div className="payment-card-bottom-content">
            {!isDefault ? defaultButton : defaultText}
          </div>
        </>
      </DataCard>
    </div>
  );
}
PaymentCard.defaultProps = defaultProps;
PaymentCard.propTypes = propTypes;
