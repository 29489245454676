import React, { useState } from 'react';
import {
  DropDown,
  ErrorMessage,
  FieldGroup,
  TooltipWidget,
} from 'anf-core-react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { TmntText } from '../Common/Text';
import ControlledInputField from '../Common/ControlledInputField/ControlledInputField';

export default function GiftCardBalanceFormFields({
  control,
  queryData,
}) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { textFor, config, giftCardCountryList } = queryData;

  const {
    checkYourBalanceHeadingText,
    checkBalanceCountryDropdownText,
    checkBalanceCountryDropDownErrorText,
    giftCardAccountNumberText,
    giftCardAccountNumberErrorText,
    checkBalanceFormTextPin,
    checkBalanceFormPinErrorText,
    giftCardCardNumberTooltipLabel,
    giftCardCardNumberTooltipDescTitle,
    giftCardCheckPhysicalCardNumberText,
    giftCardEGiftCardNumberHelpText,
  } = textFor;

  const { field, fieldState } = useController({
    name: 'gift-card-country-dropdown',
    control,
    rules: { required: true },
  });

  const { field: giftCardNumberInputField } = useController({
    name: 'gift-card-number-input',
    control,
  });

  const { field: giftCardPinInputField } = useController({
    name: 'gift-card-pin-input',
    control,
  });

  const renderToolTipWidgetField = () => {
    const handleOnClick = () => setIsTooltipOpen(!isTooltipOpen);

    const handleOnKepUp = (event) => {
      event.stopPropagation();
      setIsTooltipOpen(event.key !== 'Escape');
    };

    return (
      <TooltipWidget
        buttonLabelText={giftCardCardNumberTooltipLabel.value}
        contentPosition="bottom-right"
        contentID="gift-card-number-tooltip"
        isOpen={isTooltipOpen}
        onClick={handleOnClick}
        onKeyUp={handleOnKepUp}
      >
        <h3 style={{ color: 'white' }}>{giftCardCardNumberTooltipDescTitle?.value}</h3>
        <br />
        <TmntText tmnt={giftCardCheckPhysicalCardNumberText} />
        <br />
        <img
          src={config.physicalGiftCardImageUrl}
          alt="Physical Gift Card number is on card back"
          width="150px"
        />
        <br />
        <TmntText tmnt={giftCardEGiftCardNumberHelpText} />
        <br />
        <img
          src={config.digitalGiftCardImageUrl}
          alt="E-Gift Card Number is included in email"
          width="150px"
        />
      </TooltipWidget>
    );
  };

  const processDropDownList = (dropDownList) => dropDownList.map((op) => (
    { label: op.label.value, value: op.value }
  ));

  return (
    <FieldGroup legend={checkYourBalanceHeadingText?.value}>
      <DropDown
        id={field.name}
        isInvalid={fieldState.invalid}
        label={checkBalanceCountryDropdownText?.value}
        name={field.name}
        onChange={field.onChange}
        options={processDropDownList(giftCardCountryList)}
        value={field.value}
      >
        { fieldState.error && (
        <ErrorMessage>
          <TmntText tmnt={checkBalanceCountryDropDownErrorText} />
        </ErrorMessage>
        )}
      </DropDown>
      <div className="gift-card-account-number-field">
        <ControlledInputField
          autoComplete="on"
          label={giftCardAccountNumberText?.value}
          maxLength={16}
          name={giftCardNumberInputField.name}
          control={control}
          id={giftCardNumberInputField.name}
        >
          {renderToolTipWidgetField()}
          <ErrorMessage>
            <TmntText tmnt={giftCardAccountNumberErrorText} />
          </ErrorMessage>
        </ControlledInputField>
      </div>
      <ControlledInputField
        autoComplete="on"
        isRequired={false}
        label={checkBalanceFormTextPin?.value}
        maxLength={4}
        name={giftCardPinInputField.name}
        control={control}
        id={giftCardPinInputField.name}
      >
        <ErrorMessage>
          <TmntText tmnt={checkBalanceFormPinErrorText} />
        </ErrorMessage>
      </ControlledInputField>
    </FieldGroup>
  );
}

GiftCardBalanceFormFields.propTypes = {
  control: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  queryData: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
GiftCardBalanceFormFields.defaultProps = {
  queryData: {},
};
