import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import {
  ErrorMessage, FieldGroup, Icon,
} from 'anf-core-react';
import { useForm } from 'react-hook-form';
import ChangePasswordHeading from './ChangePasswordHeading';
import PasswordField from '../Common/PasswordInputField/PasswordField';
import KeepMeSignedIn from '../Common/KeepMeSignedIn/KeepMeSignedIn';
import { KEEP_ME_SIGN_IN_INPUT_NAME } from '../Common/KeepMeSignedIn/KeepMeSignedInCheckbox';
import FormCell from '../FormStructure/FormCell';
import FormGroup from '../FormStructure/FormGroup';
import FormWrapper from '../FormStructure/FormWrapper';
import useButtonState from '../Common/ButtonState/useButtonState';
import TmntText from '../Common/Text/TmntText';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import { changePasswordFormQuery, changePasswordRequest } from './ChangePasswordModalOperations';

const propTypes = { onSuccessfulSubmission: PropTypes.func };

const defaultProps = { onSuccessfulSubmission: () => {} };

export default function ChangePasswordForm({ onSuccessfulSubmission }) {
  const [renderButton, registerPromise] = useButtonState();
  const { loading, error: queryError, data: queryData } = useQuery(changePasswordFormQuery);

  const {
    control,
    setError,
    clearErrors,
    getValues,
    formState,
    handleSubmit,
  } = useForm();

  const [changePasswordMutation] = useMutation(changePasswordRequest, {
    onCompleted: ({ changePassword }) => {
      if (changePassword.success) {
        onSuccessfulSubmission();
      }
    },
    onError: (error) => {
      const { graphQLErrors } = error;
      const { extensions } = graphQLErrors && graphQLErrors.length && graphQLErrors[0];
      const { status, body } = extensions.response;
      setError('form', { message: body?.error[0]?.errorMessage });

      if (status === 401) {
        setError('current-password', { type: 'authError', message: queryData.textFor.invalidCurrentPasswordError?.value });
        setError('form', { message: queryData.textFor.invalidCurrentPasswordError?.value });
      } else if (status === 400) {
        setError('new-password', { type: 'newpasswordError', message: body?.error[0]?.errorMessage });
      }
    },
  });

  const validateNewConfirmPassword = (newPass, confirmPass) => newPass === confirmPass;

  const validateSubmission = (newPassword, confirmPassword) => {
    let isValid = true;

    if (!validateNewConfirmPassword(newPassword, confirmPassword)) {
      setError('new-password', { type: 'passwordMismatch' });
      setError('confirm-password', { type: 'passwordMismatch' });
      setError('form', { message: queryData.textFor.newPasswordMatchError?.value });
      isValid = false;
    }
    return isValid;
  };

  const handleOnSubmit = (fieldValues) => {
    clearErrors();

    const {
      'current-password': currentPassword,
      'new-password': newPassword,
      'confirm-password': confirmPassword,
    } = fieldValues;

    const isSubmissionValid = validateSubmission(newPassword, confirmPassword);

    try {
      const call = new Promise((resolve, reject) => {
        if (!isSubmissionValid) {
          reject();
        } else {
          changePasswordMutation({
            variables: {
              currentPassword,
              newPassword,
              keepMeSignedIn: fieldValues[KEEP_ME_SIGN_IN_INPUT_NAME],
            },
          }).then(({ data = {} }) => {
            const { changePassword } = data;
            if (!changePassword?.success) reject();
            else resolve();
          })
            .catch(() => { reject(); });
        }
      });
      registerPromise(call);
    } catch { /* do nothing */ }
  };

  const handleFailure = (fieldErrors) => {
    if (Object.keys(fieldErrors).length === 1 && fieldErrors.form) {
      handleOnSubmit(getValues());
    } else {
      setError('form', { message: queryData.textFor.invalidInputError?.value });
    }
  };

  const renderFormError = (errors) => {
    const formError = errors.form;

    if (Object.keys(formState.errors).length <= 0) return null;

    return (
      <FormCell>
        <ErrorMessage id="change-password-form-error-message">
          { formError?.message }
        </ErrorMessage>
      </FormCell>
    );
  };

  if (loading) return LOADING_MESSAGE;
  if (queryError) return ERROR_MESSAGE;

  const { textFor = {} } = queryData;
  const {
    changePasswordHeadingText,
    createNewPasswordText,
    saveButtonText,
    passwordReqNoteText,
    currentPasswordText,
    newPasswordText,
    confirmPasswordText,
    showPasswordText,
    hidePasswordText,
    processing,
    success,
    pleaseTryAgain,
  } = textFor;

  return (
    <form onSubmit={handleSubmit(handleOnSubmit, handleFailure)} name="create-password-pane" noValidate>
      <FormWrapper>
        <FormGroup>
          <FormCell>
            <ChangePasswordHeading
              changePasswordHeadingText={changePasswordHeadingText}
              createNewPasswordText={createNewPasswordText}
            />
          </FormCell>
          <FormCell>
            <FieldGroup legend="Change Password Inputs">
              <PasswordField
                id="current-password-field"
                isRequired
                label={currentPasswordText?.value}
                maxLength={25}
                name="current-password"
                showLabel={showPasswordText?.value}
                hideLabel={hidePasswordText?.value}
                control={control}
                autoComplete="current-password"
              />
              <PasswordField
                id="new-password-field"
                isRequired
                label={newPasswordText?.value}
                maxLength={25}
                name="new-password"
                showLabel={showPasswordText?.value}
                hideLabel={hidePasswordText?.value}
                control={control}
                autoComplete="new-password"
              />
              <PasswordField
                id="confirm-password-field"
                isRequired
                label={confirmPasswordText?.value}
                maxLength={25}
                name="confirm-password"
                showLabel={showPasswordText?.value}
                hideLabel={hidePasswordText?.value}
                control={control}
                autoComplete="confirm-password"
              />
            </FieldGroup>
          </FormCell>
          <FormCell>
            <KeepMeSignedIn id="change-password-keep-me-signed-in" control={control} />
          </FormCell>
          <FormCell align="center" ariaDescribedBy="new-password-field">
            <TmntText tmnt={passwordReqNoteText} />
          </FormCell>
          { renderFormError(formState.errors) }
          <FormCell>
            {
            renderButton({
              initial: {
                children: (<TmntText tmnt={saveButtonText} />),
                isFullWidth: true,
                type: 'submit',
                variant: 'secondary',
              },
              processing: {
                children: (<TmntText tmnt={processing} />), isFullWidth: true, variant: 'secondary',
              },
              error: {
                children: (<TmntText tmnt={pleaseTryAgain} />), isFullWidth: true, variant: 'secondary',
              },
              success: {
                children: (
                  <>
                    <Icon icon="check" />
                    <TmntText tmnt={success} />
                  </>
                ),
                isFullWidth: true,
                variant: 'secondary',
              },
            })
          }
          </FormCell>
        </FormGroup>
      </FormWrapper>
    </form>
  );
}

ChangePasswordForm.propTypes = propTypes;
ChangePasswordForm.defaultProps = defaultProps;
