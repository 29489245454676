import React from 'react';
import { useQuery } from '@apollo/client';
import { Button } from 'anf-core-react';
import { ERROR_MESSAGE } from '../Messages/Messages';
import LoyaltyCard from '../Common/LoyaltyCard/LoyaltyCard';
import TmntText from '../Common/Text/TmntText';
import { MY_POINTS_LOYALTY_QUERY } from './operations';

export default function MyPointsCard() {
  const { data = {}, loading: queryLoading, error } = useQuery(MY_POINTS_LOYALTY_QUERY);

  if (queryLoading) return null;
  if (error) return ERROR_MESSAGE;

  const { textFor = {}, config = {} } = data;

  const {
    myPointsTitle,
    myPointsDescription,
    pointsHistoryLabel,
    minValueLabelProgressBar,
    maxValueLabelProgressBar,
  } = textFor;
  const {
    pointsHistoryUrl, myPointsCurrentProgressBarValue, myPointsMaxProgressBarValue,
  } = config;

  return (
    <LoyaltyCard
      title={myPointsTitle?.value}
      description={<TmntText tmnt={myPointsDescription} />}
      progressBarCurrentValue={myPointsCurrentProgressBarValue}
      progressBarMaximumValue={myPointsMaxProgressBarValue}
      minValueLabelProgressBar={minValueLabelProgressBar?.value}
      maxValueLabelProgressBar={maxValueLabelProgressBar?.value}
      progressBarClassName="myPointsProgressBar"
    >
      <Button classList="points-history-btn" variant="tertiary-dark" isFullWidth href={pointsHistoryUrl}>
        {pointsHistoryLabel.value}
      </Button>
    </LoyaltyCard>
  );
}
